:root {
  --green-info: #38A160;
  --product-bg-color: white;
  --body-bg-color: var(--gray-50);
}

body.product-page {
  background: var(--body-bg-color);
}

.item-breadcrumbs .breadcrumb-container a {
  color: var(--gray-900);
}

.carousel-control {
  height: 42px;
  width: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.08), 0px 1px 2px 1px rgba(0, 0, 0, 0.06);
  border-radius: 100px;
}

.carousel-control-prev,
.carousel-control-next {
  opacity: 1;
  width: 8%;
}
@media (max-width: 1200px) {
  .carousel-control-prev,
  .carousel-control-next {
    width: 10%;
  }
}
@media (max-width: 768px) {
  .carousel-control-prev,
  .carousel-control-next {
    width: 15%;
  }
}

.carousel-body {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

.carousel-content {
  max-width: 400px;
  margin-right: 5rem;
  margin-left: 5rem;
}

.card {
  border: none;
}

.product-category-section .card:hover {
  box-shadow: 0px 16px 45px 6px rgba(0, 0, 0, 0.08), 0px 8px 10px -10px rgba(0, 0, 0, 0.04);
}
.product-category-section .card-grid {
  display: grid;
  grid-gap: 15px;
  grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
}

.no-image-item {
  height: 340px;
  width: 340px;
  background: var(--gray-100);
  border-radius: var(--border-radius);
  font-size: 2rem;
  color: var(--gray-500);
  display: flex;
  align-items: center;
  justify-content: center;
}

table .no-image-item {
  margin: auto;
  height: 84px !important;
  width: 84px !important;
}

.item-card-group-section .card {
  height: 100%;
  align-items: center;
  justify-content: center;
}
.item-card-group-section .card:hover {
  box-shadow: 0px 16px 60px rgba(0, 0, 0, 0.08), 0px 8px 30px -20px rgba(0, 0, 0, 0.04);
  transition: box-shadow 400ms;
}
.item-card-group-section .card:hover .btn-add-to-cart-list, .item-card-group-section .card:focus-within .btn-add-to-cart-list {
  visibility: visible;
}
.item-card-group-section .card:hover .like-action, .item-card-group-section .card:focus-within .like-action {
  visibility: visible;
}
.item-card-group-section .card:hover .btn-explore-variants, .item-card-group-section .card:focus-within .btn-explore-variants {
  visibility: visible;
}
.item-card-group-section .card-img-container {
  height: 210px;
  width: 100%;
}
.item-card-group-section .card-img {
  max-height: 210px;
  -o-object-fit: contain;
     object-fit: contain;
  margin-top: 1.25rem;
}
.item-card-group-section .no-image {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 220px;
  background: var(--gray-100);
  width: 100%;
  border-radius: var(--border-radius) var(--border-radius) 0 0;
  font-size: 2rem;
  color: var(--gray-500);
}
.item-card-group-section .no-image-list {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 150px;
  background: var(--gray-100);
  border-radius: var(--border-radius);
  font-size: 2rem;
  color: var(--gray-500);
  margin-top: 15px;
  margin-bottom: 15px;
}
.item-card-group-section .card-body-flex {
  display: flex;
  flex-direction: column;
}
.item-card-group-section .product-title {
  font-size: 14px;
  color: var(--gray-800);
  font-weight: 500;
}
.item-card-group-section .product-description {
  font-size: 12px;
  color: var(--text-color);
  margin: 20px 0;
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
}
.item-card-group-section .product-description p {
  margin-bottom: 0.5rem;
}
.item-card-group-section .product-category {
  font-size: 13px;
  color: var(--text-muted);
  margin: var(--margin-sm) 0;
}
.item-card-group-section .product-price {
  font-size: 18px;
  font-weight: 600;
  color: var(--text-color);
  margin: var(--margin-sm) 0;
  margin-bottom: auto !important;
}
.item-card-group-section .product-price .striked-price {
  font-weight: 500;
  font-size: 15px;
  color: var(--gray-500);
}
.item-card-group-section .product-info-green {
  color: var(--green-info);
  font-weight: 600;
}
.item-card-group-section .item-card {
  padding: var(--padding-sm);
  min-width: 300px;
}
.item-card-group-section .wishlist-card {
  padding: var(--padding-sm);
  min-width: 260px;
}
.item-card-group-section .wishlist-card .card-body-flex {
  display: flex;
  flex-direction: column;
}

.item-cart .product-code {
  padding-top: 0 !important;
}
.item-cart .btn-explore-variants {
  min-width: 135px;
  max-height: 30px;
  float: left;
  padding: 0.25rem 1rem;
}
.item-cart .input-group-prepend .cart-btn {
  border-top-left-radius: unset !important;
  border-bottom-left-radius: unset !important;
}
.item-cart .input-group-append .cart-btn {
  border-top-right-radius: unset !important;
  border-bottom-right-radius: unset !important;
}
.item-cart .remove-cart-item {
  border-radius: 6px;
  border: 1px solid var(--gray-100);
  width: 28px;
  height: 28px;
  font-weight: 300;
  color: var(--gray-700);
  background-color: var(--gray-100);
  float: left;
  cursor: pointer;
  margin-top: 0.25rem;
  justify-content: center;
}
.item-cart .remove-cart-item-logo {
  margin-top: 2px;
  margin-right: 2.2px;
  fill: var(--gray-700) !important;
}
.item-cart .number-spinner {
  width: 75%;
  min-width: 105px;
}
.item-cart .number-spinner .cart-btn {
  background: var(--gray-100);
  box-shadow: none;
  width: 24px;
  height: 28px;
  align-items: center;
  justify-content: center;
  display: flex;
  font-size: 20px;
  font-weight: 300;
  color: var(--gray-700);
}
.item-cart .number-spinner .cart-qty {
  height: 28px;
  font-size: 13px;
}
.item-cart .number-spinner .cart-qty:disabled {
  background: var(--gray-100);
  opacity: 0.65;
}

#products-grid-area .product-code {
  padding-top: 0 !important;
}
#products-grid-area .btn-explore-variants {
  min-width: 135px;
  max-height: 30px;
  float: left;
  width: 100%;
  padding: 0.25rem 1rem;
}
#products-grid-area .input-group-prepend .cart-btn {
  border-top-left-radius: unset !important;
  border-bottom-left-radius: unset !important;
}
#products-grid-area .input-group-append .cart-btn {
  border-top-right-radius: unset !important;
  border-bottom-right-radius: unset !important;
}
#products-grid-area .remove-cart-item {
  border-radius: 6px;
  border: 1px solid var(--gray-100);
  width: 28px;
  height: 28px;
  font-weight: 300;
  color: var(--gray-700);
  background-color: var(--gray-100);
  float: left;
  cursor: pointer;
  margin-top: 0px;
  justify-content: center;
}
#products-grid-area .remove-cart-item-logo {
  margin-top: 2px;
  margin-right: 2.2px;
  fill: var(--gray-700) !important;
}
#products-grid-area .number-spinner {
  width: 75%;
  min-width: 105px;
}
#products-grid-area .number-spinner .cart-btn {
  background: var(--gray-100);
  box-shadow: none;
  width: 24px;
  height: 28px;
  align-items: center;
  justify-content: center;
  display: flex;
  font-size: 20px;
  font-weight: 300;
  color: var(--gray-700);
}
#products-grid-area .number-spinner .cart-qty {
  height: 28px;
  font-size: 13px;
}
#products-grid-area .number-spinner .cart-qty:disabled {
  background: var(--gray-100);
  opacity: 0.65;
}

#products-list-area {
  padding: 0 5px;
}

.cart-qty {
  width: 70px;
}

@media (min-width: 320px) and (max-width: 990px) {
  #custom-search-box {
    width: 360px;
  }
  .cart-action-container span {
    display: none;
  }
  .input-group-prepend, .input-group-append, .input-group-append span, .remove-cart-item span {
    display: flex !important;
  }
  #products-list-area .number-spinner {
    margin-right: -20px !important;
  }
  #products-grid-area image-view .number-spinner {
    margin-right: 0 !important;
  }
  #products-grid-area .btn-explore-variants {
    margin-left: 0 !important;
  }
}
.input-group {
  width: 100% !important;
}

.shopping-cart-header {
  font-weight: bold;
}

.list-row {
  background-color: inherit;
  padding-bottom: 1rem;
  padding-top: 1.5rem !important;
  border-radius: 8px;
  border-bottom: 1px solid var(--gray-50);
}
.list-row:hover, .list-row:focus-within {
  box-shadow: 0px 16px 60px rgba(0, 0, 0, 0.08), 0px 8px 30px -20px rgba(0, 0, 0, 0.04);
  transition: box-shadow 400ms;
}
.list-row:hover .btn-add-to-cart-list, .list-row:focus-within .btn-add-to-cart-list {
  visibility: visible;
}
.list-row:hover .like-action-list, .list-row:focus-within .like-action-list {
  visibility: visible;
}
.list-row:hover .btn-explore-variants, .list-row:focus-within .btn-explore-variants {
  visibility: visible;
}
.list-row .product-code {
  padding-top: 0 !important;
}
.list-row .btn-explore-variants {
  min-width: 135px;
  max-height: 30px;
  float: left;
  padding: 0.25rem 1rem;
}
.list-row .input-group-prepend .cart-btn {
  border-top-left-radius: unset !important;
  border-bottom-left-radius: unset !important;
}
.list-row .input-group-append .cart-btn {
  border-top-right-radius: unset !important;
  border-bottom-right-radius: unset !important;
}
.list-row .remove-cart-item {
  border-radius: 6px;
  border: 1px solid var(--gray-100);
  width: 28px;
  height: 28px;
  font-weight: 300;
  color: var(--gray-700);
  background-color: var(--gray-100);
  float: left;
  cursor: pointer;
  margin-top: 0px;
  justify-content: center;
}
.list-row .remove-cart-item-logo {
  margin-top: 2px;
  margin-right: 2.2px;
  fill: var(--gray-700) !important;
}
.list-row .number-spinner {
  width: 75%;
  min-width: 105px;
  margin-right: 75px;
}
.list-row .number-spinner .cart-btn {
  background: var(--gray-100);
  box-shadow: none;
  width: 24px;
  height: 28px;
  align-items: center;
  justify-content: center;
  display: flex;
  font-size: 20px;
  font-weight: 300;
  color: var(--gray-700);
}
.list-row .number-spinner .cart-qty {
  height: 28px;
  font-size: 13px;
}
.list-row .number-spinner .cart-qty:disabled {
  background: var(--gray-100);
  opacity: 0.65;
}

.product-variants-content .input-group-prepend .cart-btn {
  border-top-left-radius: unset !important;
  border-bottom-left-radius: unset !important;
}
.product-variants-content .input-group-append .cart-btn {
  border-top-right-radius: unset !important;
  border-bottom-right-radius: unset !important;
}
.product-variants-content .remove-cart-item {
  border-radius: 6px;
  border: 1px solid var(--gray-100);
  width: 28px;
  height: 28px;
  font-weight: 300;
  color: var(--gray-700);
  background-color: var(--gray-100);
  float: left;
  cursor: pointer;
  margin-top: 0.25rem;
  justify-content: center;
}
.product-variants-content .remove-cart-item-logo {
  margin-top: 2px;
  margin-right: 2.2px;
  fill: var(--gray-700) !important;
}
.product-variants-content .number-spinner {
  width: 75%;
  min-width: 105px;
}
.product-variants-content .number-spinner .cart-btn {
  background: var(--gray-100);
  box-shadow: none;
  width: 24px;
  height: 28px;
  align-items: center;
  justify-content: center;
  display: flex;
  font-size: 20px;
  font-weight: 300;
  color: var(--gray-700);
}
.product-variants-content .number-spinner .cart-qty {
  height: 28px;
  font-size: 13px;
}
.product-variants-content .number-spinner .cart-qty:disabled {
  background: var(--gray-100);
  opacity: 0.65;
}

[data-doctype="Item Group"] .page-header,
#page-index .page-header {
  font-size: 20px;
  font-weight: 700;
  color: var(--text-color);
}
[data-doctype="Item Group"] .filters-section .title-section,
#page-index .filters-section .title-section {
  border-bottom: 1px solid var(--table-border-color);
}
[data-doctype="Item Group"] .filters-section .filter-title,
#page-index .filters-section .filter-title {
  font-weight: 500;
}
[data-doctype="Item Group"] .filters-section .clear-filters,
#page-index .filters-section .clear-filters {
  font-size: 13px;
}
[data-doctype="Item Group"] .filters-section .filter-lookup-input,
#page-index .filters-section .filter-lookup-input {
  background-color: white;
  border: 1px solid var(--gray-300);
}
[data-doctype="Item Group"] .filters-section .filter-lookup-input:focus,
#page-index .filters-section .filter-lookup-input:focus {
  border: 1px solid var(--primary);
}
[data-doctype="Item Group"] .filters-section .filter-label,
#page-index .filters-section .filter-label {
  font-size: 11px;
  font-weight: 600;
  color: var(--gray-700);
  text-transform: uppercase;
}
[data-doctype="Item Group"] .filters-section .filter-block,
#page-index .filters-section .filter-block {
  border-bottom: 1px solid var(--table-border-color);
}
[data-doctype="Item Group"] .filters-section .checkbox .label-area,
#page-index .filters-section .checkbox .label-area {
  font-size: 13px;
  color: var(--gray-800);
}

.product-filter {
  width: 14px !important;
  height: 14px !important;
}

.discount-filter:before {
  width: 14px !important;
  height: 14px !important;
}

.list-image {
  border: none !important;
  overflow: hidden;
  max-height: 200px;
  background-color: white;
}

.product-container {
  border-radius: var(--border-radius-md);
  box-shadow: var(--card-shadow);
  padding: var(--padding-md);
  background-color: var(--card-bg);
  background-color: var(--product-bg-color) !important;
  min-height: -moz-fit-content;
  min-height: fit-content;
}
.product-container .product-details {
  max-width: 50%;
}
.product-container .product-details .btn-add-to-cart {
  font-size: 14px;
}
.product-container.item-main .product-image {
  width: 100%;
}
.product-container .expand {
  max-width: 100% !important;
}
@media (max-width: 789px) {
  .product-container .product-details {
    max-width: 90% !important;
  }
  .product-container .product-details .btn-add-to-cart {
    font-size: 14px;
  }
}
.product-container .btn-add-to-wishlist svg use {
  --icon-stroke: #F47A7A;
}
.product-container .btn-view-in-wishlist svg use {
  fill: #F47A7A;
  --icon-stroke: none;
}
.product-container .product-title {
  font-size: 16px;
  font-weight: 600;
  color: var(--text-color);
  padding: 0 !important;
}
.product-container .product-description {
  font-size: 13px;
  color: var(--gray-800);
}
.product-container .product-image {
  border-color: var(--table-border-color) !important;
  padding: 15px;
}
@media (max-width: var(--md-width)) {
  .product-container .product-image {
    height: 300px;
    width: 300px;
  }
}
@media (min-width: var(--lg-width)) {
  .product-container .product-image {
    height: 350px;
    width: 350px;
  }
}
.product-container .product-image img {
  -o-object-fit: contain;
     object-fit: contain;
}
.product-container .item-slideshow {
  overflow: auto;
}
@media (max-width: var(--md-width)) {
  .product-container .item-slideshow {
    max-height: 320px;
  }
}
@media (min-width: var(--lg-width)) {
  .product-container .item-slideshow {
    max-height: 430px;
  }
}
.product-container .item-slideshow-image {
  height: 4rem;
  width: 6rem;
  -o-object-fit: contain;
     object-fit: contain;
  padding: 0.5rem;
  border: 1px solid var(--table-border-color);
  border-radius: 4px;
  cursor: pointer;
}
.product-container .item-slideshow-image:hover, .product-container .item-slideshow-image.active {
  border-color: var(--primary);
}
.product-container .item-cart .product-price {
  font-size: 22px;
  color: var(--text-color);
  font-weight: 600;
}
.product-container .item-cart .product-price .formatted-price {
  color: var(--text-muted);
  font-size: 14px;
}
.product-container .item-cart .no-stock {
  font-size: var(--text-base);
}
.product-container .item-cart .offers-heading {
  font-size: 16px !important;
  color: var(--text-color);
}
.product-container .item-cart .offers-heading .tag-icon {
  --icon-stroke: var(--gray-500);
}
.product-container .item-cart .w-30-40 {
  width: 30%;
}
@media (max-width: 992px) {
  .product-container .item-cart .w-30-40 {
    width: 40%;
  }
}
.product-container .tab-content {
  font-size: 14px;
}

.recommended-item-section {
  padding-left: 0;
}
.recommended-item-section .recommendation-header {
  font-size: 16px;
  font-weight: 500;
}
.recommended-item-section .recommendation-container {
  padding: 0.5rem;
  min-height: 0px;
}
.recommended-item-section .recommendation-container .r-item-image {
  min-height: 100px;
  width: 40%;
}
.recommended-item-section .recommendation-container .r-item-image .r-product-image {
  padding: 2px 15px;
}
.recommended-item-section .recommendation-container .r-item-image .no-image-r-item {
  display: flex;
  justify-content: center;
  background-color: var(--gray-200);
  align-items: center;
  color: var(--gray-400);
  margin-top: 0.15rem;
  border-radius: 6px;
  height: 100%;
  font-size: 24px;
}
.recommended-item-section .recommendation-container .r-item-info {
  font-size: 14px;
  padding-left: 0;
  padding-right: 10px;
  width: 60%;
}
.recommended-item-section .recommendation-container .r-item-info a {
  color: var(--gray-800);
  font-weight: 400;
}
.recommended-item-section .recommendation-container .r-item-info .item-price {
  font-size: 15px;
  font-weight: 600;
  color: var(--text-color);
}
.recommended-item-section .recommendation-container .r-item-info .striked-item-price {
  font-weight: 500;
  color: var(--gray-500);
}

.product-code {
  padding: 0.5rem 0;
  color: var(--text-muted);
  font-size: 14px;
}
.product-code .product-item-group {
  padding-left: 0.25rem;
  border-left: solid 1px var(--text-muted);
}
.product-code .product-item-code {
  padding-right: 0.5rem;
}

.item-configurator-dialog .modal-body {
  padding-bottom: var(--padding-xl);
}
.item-configurator-dialog .modal-body .status-area .alert {
  padding: var(--padding-xs) var(--padding-sm);
  font-size: var(--text-sm);
}
.item-configurator-dialog .modal-body .form-layout {
  max-height: 50vh;
  overflow-y: auto;
}
.item-configurator-dialog .modal-body .section-body .form-column .form-group .control-label {
  font-size: var(--text-md);
  color: var(--gray-700);
}
.item-configurator-dialog .modal-body .section-body .form-column .form-group .help-box {
  margin-top: 2px;
  font-size: var(--text-sm);
}

.item-group-slideshow .carousel-inner.rounded-carousel {
  border-radius: var(--card-border-radius);
}

.sub-category-container {
  padding-bottom: 0.5rem;
  margin-bottom: 1.25rem;
  border-bottom: 1px solid var(--table-border-color);
}
.sub-category-container .heading {
  color: var(--gray-500);
}

.scroll-categories .category-pill {
  display: inline-block;
  width: -moz-fit-content;
  width: fit-content;
  padding: 6px 12px;
  margin-bottom: 8px;
  background-color: #ecf5fe;
  font-size: 14px;
  border-radius: 18px;
  color: var(--blue-500);
}

.shopping-badge {
  position: relative;
  top: -10px;
  right: -12px;
  background: var(--red-600);
  align-items: center;
  height: 16px;
  font-size: 10px;
  border-radius: 50%;
}

.cart-animate {
  animation: wiggle 0.5s linear;
}

@keyframes wiggle {
  8%, 41% {
    transform: translateX(10px);
  }
  25%, 58% {
    transform: translate(-10px);
  }
  75% {
    transform: translate(5px);
  }
  92% {
    transform: translate(-5px);
  }
  0%, 100% {
    transform: translate(0);
  }
}
.total-discount {
  font-size: 14px;
  color: var(--primary-color) !important;
}

#page-cart .cart-qty {
  width: 70px;
}
#page-cart .input-group {
  width: 100% !important;
}
#page-cart .shopping-cart-header {
  font-weight: bold;
}
#page-cart .cart-container {
  color: var(--text-color);
}
#page-cart .cart-container .frappe-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: -moz-fit-content;
  height: fit-content;
}
#page-cart .cart-container .cart-items-header {
  font-weight: 600;
}
#page-cart .cart-container .cart-table tr {
  margin-bottom: 1rem;
}
#page-cart .cart-container .cart-table th, #page-cart .cart-container .cart-table tr, #page-cart .cart-container .cart-table td {
  border-color: var(--border-color);
  border-width: 1px;
}
#page-cart .cart-container .cart-table th {
  font-weight: normal;
  font-size: 13px;
  color: var(--text-muted);
  padding: var(--padding-sm) 0;
}
#page-cart .cart-container .cart-table td {
  padding: var(--padding-sm) 0;
  color: var(--text-color);
}
#page-cart .cart-container .cart-table .cart-item-image {
  width: 20%;
  min-width: 100px;
}
#page-cart .cart-container .cart-table .cart-item-image img {
  max-height: 112px;
}
#page-cart .cart-container .cart-table .cart-items .item-title {
  width: 80%;
  font-size: 14px;
  font-weight: 500;
  color: var(--text-color);
}
#page-cart .cart-container .cart-table .cart-items .item-subtitle {
  color: var(--text-muted);
  font-size: 13px;
}
#page-cart .cart-container .cart-table .cart-items .item-subtotal {
  font-size: 14px;
  font-weight: 500;
}
#page-cart .cart-container .cart-table .cart-items .sm-item-subtotal {
  font-size: 14px;
  font-weight: 500;
  display: none;
}
@media (max-width: 992px) {
  #page-cart .cart-container .cart-table .cart-items .sm-item-subtotal {
    display: unset !important;
  }
}
#page-cart .cart-container .cart-table .cart-items .item-rate {
  font-size: 13px;
  color: var(--text-muted);
}
#page-cart .cart-container .cart-table .cart-items .free-tag {
  padding: 4px 8px;
  border-radius: 4px;
  background-color: var(--dark-green-50);
}
#page-cart .cart-container .cart-table .cart-items textarea {
  width: 80%;
  height: 60px;
  font-size: 14px;
}
#page-cart .cart-container .cart-table .cart-tax-items .item-grand-total {
  font-size: 16px;
  font-weight: 700;
  color: var(--text-color);
}
@media (max-width: 992px) {
  #page-cart .cart-container .cart-table .column-sm-view {
    display: none !important;
  }
}
#page-cart .cart-container .cart-table .item-column {
  width: 50%;
}
@media (max-width: 992px) {
  #page-cart .cart-container .cart-table .item-column {
    width: 70%;
  }
}
#page-cart .cart-container .cart-table .remove-cart-item {
  border-radius: 6px;
  border: 1px solid var(--gray-100);
  width: 28px;
  height: 28px;
  font-weight: 300;
  color: var(--gray-700);
  background-color: var(--gray-100);
  float: left;
  cursor: pointer;
  margin-top: 0.25rem;
  justify-content: center;
}
#page-cart .cart-container .cart-table .remove-cart-item-logo {
  margin-top: 2px;
  margin-right: 2.2px;
  fill: var(--gray-700) !important;
}
#page-cart .cart-container .cart-payment-addresses hr {
  border-color: var(--border-color);
}
#page-cart .cart-container .payment-summary h6 {
  padding-bottom: 1rem;
  border-bottom: solid 1px var(--gray-200);
}
#page-cart .cart-container .payment-summary table {
  font-size: 14px;
}
#page-cart .cart-container .payment-summary table td {
  padding: 0;
  padding-top: 0.35rem !important;
  border: none !important;
}
#page-cart .cart-container .payment-summary table.grand-total {
  border-top: solid 1px var(--gray-200);
}
#page-cart .cart-container .payment-summary .bill-label {
  color: var(--gray-600);
}
#page-cart .cart-container .payment-summary .bill-content {
  font-weight: 500;
}
#page-cart .cart-container .payment-summary .bill-content.net-total {
  font-size: 16px;
  font-weight: 600;
}
#page-cart .cart-container .payment-summary .btn-coupon-code {
  font-size: 14px;
  border: dashed 1px var(--gray-400);
  box-shadow: none;
}
#page-cart .cart-container .number-spinner {
  width: 75%;
  min-width: 105px;
}
#page-cart .cart-container .number-spinner .cart-btn {
  background: var(--gray-100);
  box-shadow: none;
  width: 24px;
  height: 28px;
  align-items: center;
  justify-content: center;
  display: flex;
  font-size: 20px;
  font-weight: 300;
  color: var(--gray-700);
}
#page-cart .cart-container .number-spinner .cart-qty {
  height: 28px;
  font-size: 13px;
}
#page-cart .cart-container .number-spinner .cart-qty:disabled {
  background: var(--gray-100);
  opacity: 0.65;
}
#page-cart .cart-container .place-order-container .btn-place-order {
  float: left;
}
#page-cart .t-and-c-container {
  padding: 1.5rem;
}
#page-cart .t-and-c-terms {
  font-size: 14px;
}

.no-image-cart-item {
  max-height: 112px;
  display: flex;
  justify-content: center;
  background-color: var(--gray-200);
  align-items: center;
  color: var(--gray-400);
  margin-top: 0.15rem;
  border-radius: 6px;
  height: 100%;
  font-size: 24px;
}

.cart-empty.frappe-card {
  min-height: 76vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.cart-empty.frappe-card .cart-empty-message {
  font-size: 18px;
  color: var(--text-color);
  font-weight: bold;
}

.address-card .card-title {
  font-size: 14px;
  font-weight: 500;
}
.address-card .card-body {
  max-width: 80%;
}
.address-card .card-text {
  font-size: 13px;
  color: var(--gray-700);
}
.address-card .card-link {
  font-size: 13px;
}
.address-card .card-link svg use {
  stroke: var(--primary-color);
}
.address-card .btn-change-address {
  border: 1px solid var(--primary-color);
  color: var(--primary-color);
  box-shadow: none;
}

.address-header {
  margin-top: 0.15rem;
  padding: 0;
}

.btn-new-address {
  float: left;
  font-size: 15px !important;
  color: var(--primary-color) !important;
}

.btn-new-address:hover, .btn-change-address:hover {
  color: var(--primary-color) !important;
}

.modal .address-card .card-body {
  padding: var(--padding-sm);
  border-radius: var(--border-radius);
  border: 1px solid var(--dark-border-color);
}

.cart-indicator {
  position: absolute;
  text-align: center;
  width: 22px;
  height: 22px;
  right: calc(100% - 40px);
  top: 22px;
  border-radius: 66px;
  box-shadow: 0px 2px 6px rgba(17, 43, 66, 0.08), 0px 1px 4px rgba(17, 43, 66, 0.1);
  background: white;
  color: var(--primary-color);
  font-size: 14px;
}
.cart-indicator.list-indicator {
  position: unset;
  margin-right: auto;
}

.like-action {
  visibility: hidden;
  text-align: center;
  position: absolute;
  cursor: pointer;
  width: 28px;
  height: 28px;
  right: 20px;
  top: 20px;
  /* White */
  background: white;
  box-shadow: 0px 2px 6px rgba(17, 43, 66, 0.08), 0px 1px 4px rgba(17, 43, 66, 0.1);
  border-radius: 66px;
}
.like-action.like-action-wished {
  visibility: visible !important;
}
@media (max-width: 992px) {
  .like-action {
    visibility: visible !important;
  }
}

.like-action-list {
  visibility: hidden;
  text-align: center;
  position: absolute;
  cursor: pointer;
  width: 28px;
  height: 28px;
  right: 20px;
  top: 0;
  /* White */
  background: white;
  box-shadow: 0px 2px 6px rgba(17, 43, 66, 0.08), 0px 1px 4px rgba(17, 43, 66, 0.1);
  border-radius: 66px;
}
.like-action-list.like-action-wished {
  visibility: visible !important;
}
@media (max-width: 992px) {
  .like-action-list {
    visibility: visible !important;
  }
}

.like-action-item-fp {
  visibility: visible !important;
  position: unset;
  float: left;
}

.like-animate {
  animation: expand cubic-bezier(0.04, 0.4, 0.5, 0.95) 1.6s forwards 1;
}

@keyframes expand {
  30% {
    transform: scale(1.3);
  }
  50% {
    transform: scale(0.8);
  }
  70% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
.not-wished {
  cursor: pointer;
  --icon-stroke: #F47A7A !important;
}
.not-wished:hover {
  fill: #F47A7A;
}

.wished {
  --icon-stroke: none;
  fill: #F47A7A !important;
}

.list-row-checkbox:before {
  display: none;
}
.list-row-checkbox:checked:before {
  display: block;
  z-index: 1;
}

#pay-for-order {
  padding: 0.5rem 1rem;
}

.btn-explore-variants {
  visibility: hidden;
  box-shadow: none;
  margin: var(--margin-sm) 0;
  width: 90px;
  max-height: 50px;
  flex: none;
  transition: 0.3s ease;
  color: white;
  background-color: var(--orange-500);
  border: 1px solid var(--orange-500);
  font-size: 13px;
}
.btn-explore-variants:hover {
  color: white;
}

.btn-add-to-cart-list {
  visibility: hidden;
  box-shadow: none;
  margin: var(--margin-sm) 0;
  max-height: 50px;
  flex: none;
  transition: 0.3s ease;
  font-size: 13px;
}
.btn-add-to-cart-list:hover {
  color: white;
}
@media (max-width: 992px) {
  .btn-add-to-cart-list {
    visibility: visible !important;
  }
}

.go-to-cart-grid {
  max-height: 30px;
  margin-top: 1rem !important;
}

.go-to-cart {
  max-height: 30px;
  float: left;
}

.remove-wish {
  background-color: white;
  position: absolute;
  cursor: pointer;
  top: 10px;
  left: 20px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: 1px solid var(--gray-100);
  box-shadow: 0px 2px 6px rgba(17, 43, 66, 0.08), 0px 1px 4px rgba(17, 43, 66, 0.1);
}

.wish-removed {
  display: none;
}

.item-website-specification {
  font-size: 0.875rem;
}
.item-website-specification .product-title {
  font-size: 18px;
}
.item-website-specification .table {
  width: 70%;
}
.item-website-specification td {
  border: none !important;
}
.item-website-specification .spec-label {
  color: var(--gray-600);
}
.item-website-specification .spec-content {
  color: var(--gray-800);
}

.reviews-full-page {
  padding: 1rem 2rem;
}

.ratings-reviews-section {
  border-top: 1px solid #E2E6E9;
  padding: 0.5rem 1rem;
}

.reviews-header {
  font-size: 20px;
  font-weight: 600;
  color: var(--gray-800);
  display: flex;
  align-items: center;
  padding: 0;
}

.btn-write-review {
  float: left;
  padding: 0.5rem 1rem;
  font-size: 14px;
  font-weight: 400;
  border: none !important;
  box-shadow: none;
  color: var(--gray-900);
  background-color: var(--gray-100);
}
.btn-write-review:hover {
  box-shadow: var(--btn-shadow);
}

.btn-view-more {
  font-size: 14px;
}

.rating-summary-section {
  display: flex;
}

.rating-summary-title {
  margin-top: 0.15rem;
  font-size: 18px;
}

.rating-summary-numbers {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-left: solid 1px var(--gray-100);
}

.user-review-title {
  margin-top: 0.15rem;
  font-size: 15px;
  font-weight: 600;
}

.rating {
  --star-fill: var(--gray-300);
}
.rating .star-hover {
  --star-fill: var(--yellow-100);
}
.rating .star-click {
  --star-fill: var(--yellow-300);
}

.ratings-pill {
  background-color: var(--gray-100);
  padding: 0.5rem 1rem;
  border-radius: 66px;
}

.review {
  max-width: 80%;
  line-height: 1.6;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #E2E6E9;
}

.review-signature {
  display: flex;
  font-size: 13px;
  color: var(--gray-500);
  font-weight: 400;
}
.review-signature .reviewer {
  padding-left: 8px;
  color: var(--gray-600);
}

.rating-progress-bar-section {
  padding-bottom: 2rem;
}
.rating-progress-bar-section .rating-bar-title {
  margin-right: -15px;
}
.rating-progress-bar-section .rating-progress-bar {
  margin-bottom: 4px;
  height: 7px;
  margin-top: 6px;
}
.rating-progress-bar-section .rating-progress-bar .progress-bar-cosmetic {
  background-color: var(--gray-600);
  border-radius: var(--border-radius);
}

.offer-container {
  font-size: 14px;
}

#search-results-container {
  border: 1px solid var(--gray-200);
  padding: 0.25rem 1rem;
}
#search-results-container .category-chip {
  background-color: var(--gray-100);
  border: none !important;
  box-shadow: none;
}
#search-results-container .recent-search {
  padding: 0.5rem 0.5rem;
  border-radius: var(--border-radius);
}
#search-results-container .recent-search:hover {
  background-color: var(--gray-100);
}

#search-box {
  background-color: white;
  height: auto !important;
  padding-right: 2.5rem;
  border: 1px solid var(--gray-200);
}

#custom-search-box {
  background-color: white;
  height: auto;
  padding-right: 2.5rem;
  border: 1px solid var(--gray-200);
}

.search-icon {
  position: absolute;
  right: 0;
  top: 0;
  width: 2.5rem;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 1px;
}

#toggle-view {
  float: left;
}
#toggle-view .btn-primary {
  background-color: var(--gray-600);
  box-shadow: 0 0 0 0.2rem var(--gray-400);
}

.placeholder-div {
  height: 80%;
  width: -webkit-fill-available;
  padding: 50px;
  text-align: center;
  background-color: #F9FAFA;
  border-top-right-radius: calc(0.75rem - 1px);
  border-top-left-radius: calc(0.75rem - 1px);
}

.placeholder {
  font-size: 15px;
}

.list-subject a {
  color: var(--text-muted) !important;
}

[data-path=cart] .modal-backdrop {
  background-color: var(--gray-50);
}

.item-thumb {
  height: 50px;
  max-width: 80px;
  min-width: 80px;
  -o-object-fit: cover;
     object-fit: cover;
}

.brand-line {
  color: gray;
}

.btn-next, .btn-prev {
  font-size: 14px;
}

.alert-error {
  color: #e27a84;
  background-color: #fff6f7;
  border-color: #f5c6cb;
}

.font-md {
  font-size: 14px !important;
}

.in-green {
  color: var(--green-info) !important;
  font-weight: 500;
}

.has-stock {
  font-weight: 400 !important;
}

.out-of-stock {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #F47A7A;
}

.mt-minus-2 {
  margin-top: -2rem;
}

.mt-minus-1 {
  margin-top: -1rem;
}

.call-popup a:hover {
  text-decoration: underline;
}
.call-popup .for-description {
  max-height: 250px;
  overflow: scroll;
}

audio {
  height: 40px;
  width: 100%;
  max-width: 500px;
  background-color: var(--control-bg);
  border-radius: var(--border-radius-sm);
  outline: none;
}
audio-webkit-media-controls-panel {
  background: var(--control-bg);
}

.point-of-sale-app {
  display: grid;
  grid-template-columns: repeat(10, minmax(0, 1fr));
  gap: var(--margin-md);
}
.point-of-sale-app section {
  min-height: 45rem;
  height: calc(100vh - 200px);
  max-height: calc(100vh - 200px);
}
.point-of-sale-app .frappe-control {
  margin: 0 !important;
  width: 100%;
}
.point-of-sale-app .form-group {
  margin-bottom: 0px !important;
}
.point-of-sale-app .pointer-no-select, .point-of-sale-app > .payment-container > .fields-numpad-container > .number-pad .numpad-container > .numpad-btn, .point-of-sale-app > .payment-container > .payment-modes > .payment-mode-wrapper > .mode-of-payment > .cash-shortcuts > .shortcut, .point-of-sale-app > .payment-container > .payment-modes > .payment-mode-wrapper > .mode-of-payment, .point-of-sale-app > .payment-container .section-label, .point-of-sale-app > .item-details-container > .form-container > .auto-fetch-btn, .point-of-sale-app > .item-details-container > .item-details-header > .close-btn, .point-of-sale-app .invoice-wrapper, .point-of-sale-app > .customer-cart-container > .cart-container > .abs-cart-container > .numpad-section > .numpad-container > .numpad-btn, .point-of-sale-app > .customer-cart-container > .cart-container > .abs-cart-container > .cart-totals-section > .add-discount-wrapper, .point-of-sale-app > .customer-cart-container > .cart-container > .abs-cart-container > .cart-items-section > .cart-item-wrapper, .point-of-sale-app > .items-selector > .items-container > .item-wrapper, .point-of-sale-app .primary-action, .point-of-sale-app > .payment-container .submit-order-btn, .point-of-sale-app > .customer-cart-container > .cart-container > .abs-cart-container > .numpad-section > .checkout-btn, .point-of-sale-app > .customer-cart-container > .cart-container > .abs-cart-container > .cart-totals-section > .edit-cart-btn, .point-of-sale-app > .customer-cart-container > .cart-container > .abs-cart-container > .cart-totals-section > .checkout-btn {
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
.point-of-sale-app .nowrap, .point-of-sale-app > .past-order-summary > .invoice-summary-wrapper > .abs-container > .summary-container > .item-row-wrapper > .item-name, .point-of-sale-app .invoice-wrapper > .invoice-name-date > .invoice-date, .point-of-sale-app .invoice-wrapper > .invoice-name-date > .invoice-name, .point-of-sale-app > .customer-cart-container > .cart-container > .abs-cart-container > .cart-items-section > .cart-item-wrapper > .item-name-desc, .point-of-sale-app > .customer-cart-container > .customer-section > .customer-details > .customer-display > .customer-name-desc, .point-of-sale-app > .items-selector > .items-container > .item-wrapper > .item-detail > .item-name {
  overflow: hidden;
  white-space: nowrap;
}
.point-of-sale-app .image, .point-of-sale-app > .item-details-container > .item-display > .item-image > img, .point-of-sale-app > .customer-cart-container > .cart-container > .abs-cart-container > .cart-items-section > .cart-item-wrapper > .item-image > img, .point-of-sale-app > .customer-cart-container > .customer-section > .customer-details > .customer-display > .customer-image > img, .point-of-sale-app > .items-selector > .items-container > .item-wrapper .item-img, .point-of-sale-app > .items-selector > .items-container > .item-wrapper .item-display > img {
  height: 100% !important;
  -o-object-fit: cover;
     object-fit: cover;
}
.point-of-sale-app .abbr, .point-of-sale-app > .item-details-container > .item-display > .item-image > .item-abbr, .point-of-sale-app > .customer-cart-container > .cart-container > .abs-cart-container > .cart-items-section > .cart-item-wrapper > .item-abbr, .point-of-sale-app > .customer-cart-container > .customer-section > .customer-details > .customer-display > .customer-abbr {
  background-color: var(--control-bg);
  font-size: var(--text-3xl);
}
.point-of-sale-app .label, .point-of-sale-app > .payment-container .section-label, .point-of-sale-app > .payment-container .point-of-sale-app > .items-selector > .filter-section > .section-label, .point-of-sale-app > .payment-container .point-of-sale-app > .customer-cart-container > .customer-section > .customer-details > .header > .section-label, .point-of-sale-app > .customer-cart-container > .cart-container > .abs-cart-container > .cart-label, .point-of-sale-app > .point-of-sale-app.customer-cart-container > .customer-cart-container.customer-section > .cart-container.customer-details > .abs-cart-container.header > .cart-label, .point-of-sale-app > .customer-cart-container > .customer-section > .customer-details > .transactions-label, .point-of-sale-app > .customer-cart-container.point-of-sale-app > .customer-section.items-selector > .customer-details.filter-section > .transactions-label, .point-of-sale-app > .point-of-sale-app.customer-cart-container > .customer-cart-container.customer-section > .customer-section.customer-details > .customer-details.header > .transactions-label, .point-of-sale-app > .customer-cart-container > .customer-section > .customer-details > .header > .label, .point-of-sale-app > .items-selector > .filter-section > .label {
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: var(--text-lg);
}
.point-of-sale-app .pos-card, .point-of-sale-app > .past-order-summary > .invoice-summary-wrapper, .point-of-sale-app > .past-order-list, .point-of-sale-app > .payment-container > .payment-modes > .payment-mode-wrapper > .mode-of-payment, .point-of-sale-app > .payment-container, .point-of-sale-app > .item-details-container, .point-of-sale-app > .customer-cart-container > .cart-container, .point-of-sale-app > .customer-cart-container > .customer-section, .point-of-sale-app > .items-selector {
  background-color: var(--fg-color);
  box-shadow: var(--shadow-base);
  border-radius: var(--border-radius-md);
}
.point-of-sale-app .seperator {
  margin-right: var(--margin-sm);
  margin-left: var(--margin-md);
  border-bottom: 1px solid var(--gray-300);
}
.point-of-sale-app .primary-action, .point-of-sale-app > .payment-container .submit-order-btn, .point-of-sale-app > .customer-cart-container > .cart-container > .abs-cart-container > .numpad-section > .checkout-btn, .point-of-sale-app > .customer-cart-container > .cart-container > .abs-cart-container > .cart-totals-section > .edit-cart-btn, .point-of-sale-app > .customer-cart-container > .cart-container > .abs-cart-container > .cart-totals-section > .checkout-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--padding-sm);
  margin-top: var(--margin-sm);
  border-radius: var(--border-radius-md);
  font-size: var(--text-lg);
  font-weight: 700;
}
.point-of-sale-app .highlighted-numpad-btn {
  box-shadow: inset 0 0px 4px 0px rgba(0, 0, 0, 0.15) !important;
  font-weight: 700;
  background-color: var(--control-bg);
}
.point-of-sale-app > .items-selector {
  grid-column: span 6/span 6;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.point-of-sale-app > .items-selector > .filter-section {
  display: grid;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  background-color: var(--fg-color);
  padding: var(--padding-lg);
  padding-bottom: var(--padding-sm);
  align-items: center;
}
.point-of-sale-app > .items-selector > .filter-section > .label, .point-of-sale-app > .payment-container .point-of-sale-app > .items-selector > .filter-section > .section-label, .point-of-sale-app > .payment-container .point-of-sale-app > .customer-cart-container > .customer-section.point-of-sale-app > .customer-details.items-selector > .header.filter-section > .section-label, .point-of-sale-app > .customer-cart-container.point-of-sale-app > .cart-container.items-selector > .abs-cart-container.filter-section > .cart-label, .point-of-sale-app > .point-of-sale-app.customer-cart-container > .customer-cart-container.customer-section.point-of-sale-app > .cart-container.customer-details.items-selector > .abs-cart-container.header.filter-section > .cart-label, .point-of-sale-app > .customer-cart-container.point-of-sale-app > .customer-section.items-selector > .customer-details.filter-section > .transactions-label, .point-of-sale-app > .point-of-sale-app.customer-cart-container > .customer-cart-container.customer-section.point-of-sale-app > .customer-section.customer-details.items-selector > .customer-details.header.filter-section > .transactions-label, .point-of-sale-app > .customer-cart-container > .customer-section.point-of-sale-app > .customer-details.items-selector > .header.filter-section > .label {
  grid-column: span 4/span 4;
  padding-bottom: var(--padding-xs);
}
.point-of-sale-app > .items-selector > .filter-section > .search-field {
  grid-column: span 5/span 5;
  display: flex;
  align-items: center;
  margin: 0px var(--margin-sm);
}
.point-of-sale-app > .items-selector > .filter-section > .item-group-field {
  grid-column: span 3/span 3;
  display: flex;
  align-items: center;
}
.point-of-sale-app > .items-selector > .items-container {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: var(--margin-lg);
  padding: var(--padding-lg);
  padding-top: var(--padding-xs);
  overflow-y: scroll;
  overflow-x: hidden;
}
.point-of-sale-app > .items-selector > .items-container:after {
  content: "";
  display: block;
  height: 1px;
}
.point-of-sale-app > .items-selector > .items-container > .item-wrapper {
  border-radius: var(--border-radius-md);
  box-shadow: var(--shadow-base);
  position: relative;
}
.point-of-sale-app > .items-selector > .items-container > .item-wrapper:hover {
  transform: scale(1.02, 1.02);
}
.point-of-sale-app > .items-selector > .items-container > .item-wrapper .item-qty-pill {
  position: absolute;
  display: flex;
  margin: var(--margin-sm);
  justify-content: flex-end;
  left: 0px;
}
.point-of-sale-app > .items-selector > .items-container > .item-wrapper .item-display {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--border-radius-md);
  margin: var(--margin-sm);
  margin-bottom: 0px;
  min-height: 8rem;
  height: 8rem;
}
.point-of-sale-app > .items-selector > .items-container > .item-wrapper .item-img {
  border-radius: 8px 8px 0 0;
  -o-object-fit: cover;
     object-fit: cover;
}
.point-of-sale-app > .items-selector > .items-container > .item-wrapper > .item-detail {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 3.5rem;
  height: 3.5rem;
  padding-right: var(--padding-sm);
  padding-left: var(--padding-sm);
}
.point-of-sale-app > .items-selector > .items-container > .item-wrapper > .item-detail > .item-name {
  display: flex;
  align-items: center;
  font-size: var(--text-md);
}
.point-of-sale-app > .items-selector > .items-container > .item-wrapper > .item-detail > .item-rate {
  font-weight: 700;
}
.point-of-sale-app > .customer-cart-container {
  grid-column: span 4/span 4;
  display: flex;
  flex-direction: column;
}
.point-of-sale-app > .customer-cart-container > .customer-section {
  display: flex;
  flex-direction: column;
  padding: var(--padding-md) var(--padding-lg);
  overflow: visible;
}
.point-of-sale-app > .customer-cart-container > .customer-section > .customer-field {
  display: flex;
  align-items: center;
  padding-top: var(--padding-xs);
}
.point-of-sale-app > .customer-cart-container > .customer-section > .customer-details {
  display: flex;
  flex-direction: column;
  background-color: var(--fg-color);
}
.point-of-sale-app > .customer-cart-container > .customer-section > .customer-details > .header {
  display: flex;
  margin-bottom: var(--margin-md);
  justify-content: space-between;
  padding-top: var(--padding-md);
}
.point-of-sale-app > .customer-cart-container > .customer-section > .customer-details > .header > .close-details-btn {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.point-of-sale-app > .customer-cart-container > .customer-section > .customer-details > .customer-display {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.point-of-sale-app > .customer-cart-container > .customer-section > .customer-details > .customer-display > .customer-image {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  color: var(--text-light);
  margin-left: var(--margin-md);
}
.point-of-sale-app > .customer-cart-container > .customer-section > .customer-details > .customer-display > .customer-image > img {
  border-radius: 50%;
}
.point-of-sale-app > .customer-cart-container > .customer-section > .customer-details > .customer-display > .customer-abbr {
  font-size: var(--text-2xl);
}
.point-of-sale-app > .customer-cart-container > .customer-section > .customer-details > .customer-display > .customer-name-desc {
  display: flex;
  flex-direction: column;
  margin-left: auto;
}
.point-of-sale-app > .customer-cart-container > .customer-section > .customer-details > .customer-display > .customer-name-desc > .customer-name {
  font-weight: 700;
  font-size: var(--text-lg);
}
.point-of-sale-app > .customer-cart-container > .customer-section > .customer-details > .customer-display > .customer-name-desc > .customer-desc {
  font-weight: 500;
  font-size: var(--text-sm);
}
.point-of-sale-app > .customer-cart-container > .customer-section > .customer-details > .customer-display > .reset-customer-btn {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.point-of-sale-app > .customer-cart-container > .customer-section > .customer-details > .customer-fields-container {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  margin-top: var(--margin-md);
  -moz-column-gap: var(--padding-sm);
       column-gap: var(--padding-sm);
  row-gap: var(--padding-xs);
}
.point-of-sale-app > .customer-cart-container > .customer-section > .customer-details > .transactions-label {
  margin-top: var(--margin-md);
  margin-bottom: var(--margin-sm);
}
.point-of-sale-app > .customer-cart-container > .customer-section > .customer-transactions {
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  margin-left: -12px;
  padding-left: 12px;
  margin-right: -10px;
}
.point-of-sale-app > .customer-cart-container > .customer-section > .customer-transactions > .no-transactions-placeholder {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--gray-50);
  border-radius: var(--border-radius-md);
}
.point-of-sale-app > .customer-cart-container > .cart-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: var(--margin-md);
  position: relative;
  height: 100%;
}
.point-of-sale-app > .customer-cart-container > .cart-container > .abs-cart-container {
  position: absolute;
  display: flex;
  flex-direction: column;
  padding: var(--padding-lg);
  width: 100%;
  height: 100%;
}
.point-of-sale-app > .customer-cart-container > .cart-container > .abs-cart-container > .cart-label {
  padding-bottom: var(--padding-md);
}
.point-of-sale-app > .customer-cart-container > .cart-container > .abs-cart-container > .cart-header {
  display: flex;
  width: 100%;
  font-size: var(--text-md);
  padding-bottom: var(--padding-md);
}
.point-of-sale-app > .customer-cart-container > .cart-container > .abs-cart-container > .cart-header > .name-header {
  flex: 1 1 0%;
}
.point-of-sale-app > .customer-cart-container > .cart-container > .abs-cart-container > .cart-header > .qty-header {
  margin-left: var(--margin-lg);
  text-align: center;
}
.point-of-sale-app > .customer-cart-container > .cart-container > .abs-cart-container > .cart-header > .rate-amount-header {
  text-align: left;
  margin-left: var(--margin-sm);
}
.point-of-sale-app > .customer-cart-container > .cart-container > .abs-cart-container .no-item-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--control-bg);
  border-radius: var(--border-radius-md);
  font-size: var(--text-md);
  font-weight: 500;
  width: 100%;
  height: 100%;
}
.point-of-sale-app > .customer-cart-container > .cart-container > .abs-cart-container > .cart-items-section {
  display: flex;
  flex-direction: column;
  flex: 1 1 0%;
  overflow-y: scroll;
}
.point-of-sale-app > .customer-cart-container > .cart-container > .abs-cart-container > .cart-items-section > .cart-item-wrapper {
  display: flex;
  align-items: center;
  padding: var(--padding-sm);
  border-radius: var(--border-radius-md);
  margin-left: var(--margin-sm);
}
.point-of-sale-app > .customer-cart-container > .cart-container > .abs-cart-container > .cart-items-section > .cart-item-wrapper:hover {
  background-color: var(--control-bg);
}
.point-of-sale-app > .customer-cart-container > .cart-container > .abs-cart-container > .cart-items-section > .cart-item-wrapper > .item-image {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  border-radius: var(--border-radius-md);
  color: var(--text-light);
  margin-left: var(--margin-md);
}
.point-of-sale-app > .customer-cart-container > .cart-container > .abs-cart-container > .cart-items-section > .cart-item-wrapper > .item-abbr {
  font-size: var(--text-lg);
}
.point-of-sale-app > .customer-cart-container > .cart-container > .abs-cart-container > .cart-items-section > .cart-item-wrapper > .item-name-desc {
  display: flex;
  flex-direction: column;
  flex: 1 1 0%;
  flex-shrink: 1;
}
.point-of-sale-app > .customer-cart-container > .cart-container > .abs-cart-container > .cart-items-section > .cart-item-wrapper > .item-name-desc > .item-name {
  font-weight: 700;
}
.point-of-sale-app > .customer-cart-container > .cart-container > .abs-cart-container > .cart-items-section > .cart-item-wrapper > .item-name-desc > .item-desc {
  font-size: var(--text-sm);
  color: var(--gray-600);
  font-weight: 500;
}
.point-of-sale-app > .customer-cart-container > .cart-container > .abs-cart-container > .cart-items-section > .cart-item-wrapper > .item-qty-rate {
  display: flex;
  flex-shrink: 0;
  text-align: left;
  margin-right: var(--margin-md);
}
.point-of-sale-app > .customer-cart-container > .cart-container > .abs-cart-container > .cart-items-section > .cart-item-wrapper > .item-qty-rate > .item-qty {
  display: flex;
  align-items: center;
  margin-left: var(--margin-lg);
  font-weight: 700;
}
.point-of-sale-app > .customer-cart-container > .cart-container > .abs-cart-container > .cart-items-section > .cart-item-wrapper > .item-qty-rate > .item-rate-amount {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  text-align: left;
}
.point-of-sale-app > .customer-cart-container > .cart-container > .abs-cart-container > .cart-items-section > .cart-item-wrapper > .item-qty-rate > .item-rate-amount > .item-rate {
  font-weight: 700;
}
.point-of-sale-app > .customer-cart-container > .cart-container > .abs-cart-container > .cart-items-section > .cart-item-wrapper > .item-qty-rate > .item-rate-amount > .item-amount {
  font-size: var(--text-md);
  font-weight: 600;
}
.point-of-sale-app > .customer-cart-container > .cart-container > .abs-cart-container > .cart-totals-section {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  width: 100%;
  margin-top: var(--margin-md);
}
.point-of-sale-app > .customer-cart-container > .cart-container > .abs-cart-container > .cart-totals-section > .add-discount-wrapper {
  display: none;
  align-items: center;
  border-radius: var(--border-radius-md);
  border: 1px dashed var(--gray-500);
  padding: var(--padding-sm) var(--padding-md);
  margin-bottom: var(--margin-sm);
}
.point-of-sale-app > .customer-cart-container > .cart-container > .abs-cart-container > .cart-totals-section > .add-discount-wrapper > .add-discount-field {
  width: 100%;
}
.point-of-sale-app > .customer-cart-container > .cart-container > .abs-cart-container > .cart-totals-section > .add-discount-wrapper .discount-icon {
  margin-left: var(--margin-sm);
}
.point-of-sale-app > .customer-cart-container > .cart-container > .abs-cart-container > .cart-totals-section > .add-discount-wrapper .edit-discount-btn {
  display: flex;
  align-items: center;
  font-weight: 500;
  color: var(--dark-green-500);
}
.point-of-sale-app > .customer-cart-container > .cart-container > .abs-cart-container > .cart-totals-section > .net-total-container, .point-of-sale-app > .customer-cart-container > .cart-container > .abs-cart-container > .cart-totals-section > .item-qty-total-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--padding-sm) 0px;
  font-weight: 500;
  font-size: var(--text-md);
}
.point-of-sale-app > .customer-cart-container > .cart-container > .abs-cart-container > .cart-totals-section > .item-qty-total-container {
  padding: 5px 0px 0px 0px;
}
.point-of-sale-app > .customer-cart-container > .cart-container > .abs-cart-container > .cart-totals-section > .taxes-container {
  display: none;
  flex-direction: column;
  font-weight: 500;
  font-size: var(--text-md);
}
.point-of-sale-app > .customer-cart-container > .cart-container > .abs-cart-container > .cart-totals-section > .taxes-container > .tax-row {
  display: flex;
  justify-content: space-between;
  line-height: var(--text-3xl);
}
.point-of-sale-app > .customer-cart-container > .cart-container > .abs-cart-container > .cart-totals-section > .grand-total-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--padding-sm) 0px;
  font-weight: 700;
  font-size: var(--text-lg);
}
.point-of-sale-app > .customer-cart-container > .cart-container > .abs-cart-container > .cart-totals-section > .checkout-btn {
  background-color: var(--blue-200);
  color: white;
}
.point-of-sale-app > .customer-cart-container > .cart-container > .abs-cart-container > .cart-totals-section > .edit-cart-btn {
  display: none;
  background-color: var(--control-bg);
  font-weight: 500;
  transition: all 0.15s ease-in-out;
}
.point-of-sale-app > .customer-cart-container > .cart-container > .abs-cart-container > .cart-totals-section > .edit-cart-btn:hover {
  background-color: var(--control-bg);
  color: var(--text-light);
  font-weight: 700;
}
.point-of-sale-app > .customer-cart-container > .cart-container > .abs-cart-container > .numpad-section {
  display: none;
  flex-direction: column;
  flex-shrink: 0;
  margin-top: var(--margin-sm);
  padding: var(--padding-sm);
  padding-bottom: 0px;
  width: 100%;
}
.point-of-sale-app > .customer-cart-container > .cart-container > .abs-cart-container > .numpad-section > .numpad-totals {
  display: flex;
  justify-content: space-between;
  margin-bottom: var(--margin-md);
  font-size: var(--text-md);
  font-weight: 700;
}
.point-of-sale-app > .customer-cart-container > .cart-container > .abs-cart-container > .numpad-section > .numpad-container {
  display: grid;
  grid-template-columns: repeat(5, minmax(0, 1fr));
  gap: var(--margin-md);
  margin-bottom: var(--margin-md);
}
.point-of-sale-app > .customer-cart-container > .cart-container > .abs-cart-container > .numpad-section > .numpad-container > .numpad-btn {
  border-radius: var(--border-radius-md);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--padding-md);
  box-shadow: var(--shadow-sm);
}
.point-of-sale-app > .customer-cart-container > .cart-container > .abs-cart-container > .numpad-section > .numpad-container > .col-span-2 {
  grid-column: span 2/span 2;
}
.point-of-sale-app > .customer-cart-container > .cart-container > .abs-cart-container > .numpad-section > .numpad-container > .remove-btn {
  font-weight: 700;
  color: var(--red-500);
}
.point-of-sale-app > .customer-cart-container > .cart-container > .abs-cart-container > .numpad-section > .checkout-btn {
  margin: 0px;
  margin-bottom: var(--margin-sm);
  background-color: var(--blue-200);
  color: white;
}
.point-of-sale-app .invoice-wrapper {
  display: flex;
  justify-content: space-between;
  border-radius: var(--border-radius-md);
  padding: var(--padding-sm);
}
.point-of-sale-app .invoice-wrapper:hover {
  background-color: var(--gray-50);
}
.point-of-sale-app .invoice-wrapper > .invoice-name-date {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.point-of-sale-app .invoice-wrapper > .invoice-name-date > .invoice-name {
  font-size: var(--text-md);
  font-weight: 700;
}
.point-of-sale-app .invoice-wrapper > .invoice-name-date > .invoice-date {
  font-size: var(--text-sm);
  display: flex;
  align-items: center;
}
.point-of-sale-app .invoice-wrapper > .invoice-total-status {
  display: flex;
  flex-direction: column;
  font-weight: 500;
  font-size: var(--text-sm);
  margin-right: var(--margin-md);
}
.point-of-sale-app .invoice-wrapper > .invoice-total-status > .invoice-total {
  margin-bottom: var(--margin-xs);
  font-size: var(--text-base);
  font-weight: 700;
  text-align: left;
}
.point-of-sale-app .invoice-wrapper > .invoice-total-status > .invoice-status {
  display: flex;
  align-items: center;
  justify-content: left;
}
.point-of-sale-app > .item-details-container {
  grid-column: span 4/span 4;
  display: none;
  flex-direction: column;
  padding: var(--padding-lg);
  padding-top: var(--padding-md);
}
.point-of-sale-app > .item-details-container > .item-details-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: var(--margin-md);
}
.point-of-sale-app > .item-details-container > .item-display {
  display: flex;
}
.point-of-sale-app > .item-details-container > .item-display > .item-name-desc-price {
  flex: 1 1 0%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-left: var(--margin-md);
}
.point-of-sale-app > .item-details-container > .item-display > .item-name-desc-price > .item-name {
  font-size: var(--text-3xl);
  font-weight: 600;
}
.point-of-sale-app > .item-details-container > .item-display > .item-name-desc-price > .item-desc {
  font-size: var(--text-md);
  font-weight: 500;
}
.point-of-sale-app > .item-details-container > .item-display > .item-name-desc-price > .item-price {
  font-size: var(--text-3xl);
  font-weight: 700;
}
.point-of-sale-app > .item-details-container > .item-display > .item-image {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 11rem;
  height: 11rem;
  border-radius: var(--border-radius-md);
  margin-right: var(--margin-md);
  color: var(--gray-500);
}
.point-of-sale-app > .item-details-container > .item-display > .item-image > .item-abbr {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--border-radius-md);
  font-size: var(--text-3xl);
  width: 100%;
  height: 100%;
}
.point-of-sale-app > .item-details-container > .discount-section {
  display: flex;
  align-items: center;
  margin-bottom: var(--margin-sm);
}
.point-of-sale-app > .item-details-container > .discount-section > .item-rate {
  font-weight: 500;
  margin-left: var(--margin-sm);
  text-decoration: line-through;
}
.point-of-sale-app > .item-details-container > .discount-section > .item-discount {
  padding: 3px var(--padding-sm);
  border-radius: var(--border-radius-sm);
  background-color: var(--green-100);
  color: var(--dark-green-500);
  font-size: var(--text-sm);
  font-weight: 700;
}
.point-of-sale-app > .item-details-container > .form-container {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  -moz-column-gap: var(--padding-md);
       column-gap: var(--padding-md);
}
.point-of-sale-app > .item-details-container > .form-container > .auto-fetch-btn {
  margin: var(--margin-xs);
}
.point-of-sale-app > .payment-container {
  grid-column: span 6/span 6;
  display: none;
  flex-direction: column;
  padding: var(--padding-lg);
}
.point-of-sale-app > .payment-container .border-primary {
  border: 1px solid var(--blue-500);
}
.point-of-sale-app > .payment-container .submit-order-btn {
  background-color: var(--blue-500);
  color: white;
}
.point-of-sale-app > .payment-container .section-label {
  margin-bottom: var(--margin-md);
}
.point-of-sale-app > .payment-container > .payment-modes {
  display: flex;
  padding-bottom: var(--padding-sm);
  margin-bottom: var(--margin-sm);
  overflow-x: scroll;
  overflow-y: hidden;
  flex-shrink: 0;
}
.point-of-sale-app > .payment-container > .payment-modes > .payment-mode-wrapper {
  min-width: 40%;
  padding: var(--padding-xs);
}
.point-of-sale-app > .payment-container > .payment-modes > .payment-mode-wrapper > .mode-of-payment {
  padding: var(--padding-md) var(--padding-lg);
}
.point-of-sale-app > .payment-container > .payment-modes > .payment-mode-wrapper > .mode-of-payment > .pay-amount {
  display: inline;
  float: left;
  font-weight: 700;
}
.point-of-sale-app > .payment-container > .payment-modes > .payment-mode-wrapper > .mode-of-payment > .mode-of-payment-control {
  display: none;
  align-items: center;
  margin-top: var(--margin-sm);
  margin-bottom: var(--margin-xs);
}
.point-of-sale-app > .payment-container > .payment-modes > .payment-mode-wrapper > .mode-of-payment > .loyalty-amount-name {
  display: none;
  float: left;
  font-weight: 700;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.point-of-sale-app > .payment-container > .payment-modes > .payment-mode-wrapper > .mode-of-payment > .cash-shortcuts {
  display: none;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: var(--margin-sm);
  font-size: var(--text-sm);
  text-align: center;
}
.point-of-sale-app > .payment-container > .payment-modes > .payment-mode-wrapper > .mode-of-payment > .cash-shortcuts > .shortcut {
  border-radius: var(--border-radius-sm);
  background-color: var(--control-bg);
  font-weight: 500;
  padding: var(--padding-xs) var(--padding-sm);
  transition: all 0.15s ease-in-out;
}
.point-of-sale-app > .payment-container > .payment-modes > .payment-mode-wrapper > .mode-of-payment > .cash-shortcuts > .shortcut:hover {
  background-color: var(--control-bg);
}
.point-of-sale-app > .payment-container > .payment-modes > .payment-mode-wrapper > .loyalty-card {
  display: flex;
  flex-direction: column;
}
.point-of-sale-app > .payment-container > .fields-numpad-container {
  display: flex;
  flex: 1;
  height: 100%;
  position: relative;
  justify-content: flex-end;
}
.point-of-sale-app > .payment-container > .fields-numpad-container > .fields-section {
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 50%;
  height: 100%;
  padding-bottom: var(--margin-md);
}
.point-of-sale-app > .payment-container > .fields-numpad-container > .fields-section .invoice-fields {
  overflow-y: scroll;
  height: 100%;
  padding-left: var(--padding-sm);
}
.point-of-sale-app > .payment-container > .fields-numpad-container > .number-pad {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  max-width: 50%;
}
.point-of-sale-app > .payment-container > .fields-numpad-container > .number-pad .numpad-container {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: var(--margin-md);
  margin-bottom: var(--margin-md);
}
.point-of-sale-app > .payment-container > .fields-numpad-container > .number-pad .numpad-container > .numpad-btn {
  border-radius: var(--border-radius-md);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--padding-md);
  box-shadow: var(--shadow-sm);
}
.point-of-sale-app > .payment-container > .totals-section {
  display: flex;
  margin-top: auto;
  margin-bottom: var(--margin-sm);
  justify-content: center;
  flex-direction: column;
  flex-shrink: 0;
}
.point-of-sale-app > .payment-container > .totals-section > .totals {
  display: flex;
  background-color: var(--control-bg);
  justify-content: center;
  padding: var(--padding-md);
  border-radius: var(--border-radius-md);
}
.point-of-sale-app > .payment-container > .totals-section > .totals > .col {
  flex-grow: 1;
  text-align: center;
}
.point-of-sale-app > .payment-container > .totals-section > .totals > .col > .total-label {
  font-size: var(--text-md);
  font-weight: 500;
}
.point-of-sale-app > .payment-container > .totals-section > .totals > .col > .value {
  font-size: var(--text-2xl);
  font-weight: 700;
}
.point-of-sale-app > .payment-container > .totals-section > .totals > .seperator-y {
  margin-right: var(--margin-sm);
  margin-left: var(--margin-sm);
  border-left: 1px solid var(--gray-300);
}
.point-of-sale-app > .payment-container > .totals-section > .number-pad {
  display: none;
}
.point-of-sale-app > .past-order-list {
  grid-column: span 4/span 4;
  display: none;
  flex-direction: column;
  overflow: hidden;
}
.point-of-sale-app > .past-order-list > .filter-section {
  display: flex;
  flex-direction: column;
  background-color: var(--fg-color);
  padding: var(--padding-lg);
}
.point-of-sale-app > .past-order-list > .filter-section > .search-field {
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: var(--margin-md);
  margin-bottom: var(--margin-xs);
}
.point-of-sale-app > .past-order-list > .filter-section > .status-field {
  width: 100%;
  display: flex;
  align-items: center;
}
.point-of-sale-app > .past-order-list > .invoices-container {
  padding: var(--padding-lg);
  padding-top: 0px;
  overflow-x: hidden;
  overflow-y: scroll;
}
.point-of-sale-app > .past-order-summary {
  display: none;
  grid-column: span 6/span 6;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.point-of-sale-app > .past-order-summary > .no-summary-placeholder {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: var(--gray-50);
  font-weight: 500;
  border-radius: var(--border-radius-md);
}
.point-of-sale-app > .past-order-summary > .invoice-summary-wrapper {
  display: none;
  position: relative;
  width: 31rem;
  height: 100%;
}
.point-of-sale-app > .past-order-summary > .invoice-summary-wrapper > .abs-container {
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: var(--padding-lg);
}
.point-of-sale-app > .past-order-summary > .invoice-summary-wrapper > .abs-container > .upper-section {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: var(--margin-md);
}
.point-of-sale-app > .past-order-summary > .invoice-summary-wrapper > .abs-container > .upper-section > .left-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  padding-left: var(--padding-sm);
}
.point-of-sale-app > .past-order-summary > .invoice-summary-wrapper > .abs-container > .upper-section > .left-section > .customer-name {
  font-size: var(--text-2xl);
  font-weight: 700;
}
.point-of-sale-app > .past-order-summary > .invoice-summary-wrapper > .abs-container > .upper-section > .left-section > .customer-email {
  font-size: var(--text-md);
  font-weight: 500;
}
.point-of-sale-app > .past-order-summary > .invoice-summary-wrapper > .abs-container > .upper-section > .left-section > .cashier {
  font-size: var(--text-md);
  font-weight: 500;
  color: var(--gray-600);
  margin-top: auto;
}
.point-of-sale-app > .past-order-summary > .invoice-summary-wrapper > .abs-container > .upper-section > .right-section {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
}
.point-of-sale-app > .past-order-summary > .invoice-summary-wrapper > .abs-container > .upper-section > .right-section > .paid-amount {
  font-size: var(--text-2xl);
  font-weight: 700;
}
.point-of-sale-app > .past-order-summary > .invoice-summary-wrapper > .abs-container > .upper-section > .right-section > .invoice-name {
  font-size: var(--text-md);
  font-weight: 500;
  color: var(--gray-600);
  margin-bottom: var(--margin-sm);
}
.point-of-sale-app > .past-order-summary > .invoice-summary-wrapper > .abs-container > .summary-container {
  display: flex;
  flex-direction: column;
  border-radius: var(--border-radius-md);
  background-color: var(--control-bg);
  margin: var(--margin-md) 0px;
}
.point-of-sale-app > .past-order-summary > .invoice-summary-wrapper > .abs-container > .summary-container > .summary-row-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--padding-sm) var(--padding-md);
}
.point-of-sale-app > .past-order-summary > .invoice-summary-wrapper > .abs-container > .summary-container > .taxes-wrapper {
  display: flex;
  flex-direction: column;
  padding: 0px var(--padding-md);
}
.point-of-sale-app > .past-order-summary > .invoice-summary-wrapper > .abs-container > .summary-container > .taxes-wrapper > .tax-row {
  display: flex;
  justify-content: space-between;
  font-size: var(--text-md);
  line-height: var(--text-3xl);
}
.point-of-sale-app > .past-order-summary > .invoice-summary-wrapper > .abs-container > .summary-container > .item-row-wrapper {
  display: flex;
  align-items: center;
  padding: var(--padding-sm) var(--padding-md);
}
.point-of-sale-app > .past-order-summary > .invoice-summary-wrapper > .abs-container > .summary-container > .item-row-wrapper > .item-name {
  font-weight: 500;
  margin-left: var(--margin-md);
}
.point-of-sale-app > .past-order-summary > .invoice-summary-wrapper > .abs-container > .summary-container > .item-row-wrapper > .item-qty {
  font-weight: 500;
  margin-right: auto;
}
.point-of-sale-app > .past-order-summary > .invoice-summary-wrapper > .abs-container > .summary-container > .item-row-wrapper > .item-rate-disc {
  display: flex;
  text-align: left;
  margin-right: var(--margin-md);
  justify-content: flex-end;
}
.point-of-sale-app > .past-order-summary > .invoice-summary-wrapper > .abs-container > .summary-container > .item-row-wrapper > .item-rate-disc > .item-disc {
  color: var(--dark-green-500);
}
.point-of-sale-app > .past-order-summary > .invoice-summary-wrapper > .abs-container > .summary-container > .item-row-wrapper > .item-rate-disc > .item-rate {
  font-weight: 500;
  margin-right: var(--margin-md);
}
.point-of-sale-app > .past-order-summary > .invoice-summary-wrapper > .abs-container > .summary-container > .grand-total {
  font-weight: 700;
}
.point-of-sale-app > .past-order-summary > .invoice-summary-wrapper > .abs-container > .summary-container > .payments {
  font-weight: 700;
}
.point-of-sale-app > .past-order-summary > .invoice-summary-wrapper > .abs-container > .summary-btns {
  display: flex;
  justify-content: space-between;
}
.point-of-sale-app > .past-order-summary > .invoice-summary-wrapper > .abs-container > .summary-btns > .summary-btn {
  flex: 1;
  margin: 0px var(--margin-xs);
}
.point-of-sale-app > .past-order-summary > .invoice-summary-wrapper > .abs-container > .summary-btns > .new-btn {
  background-color: var(--blue-500);
  color: white;
  font-weight: 500;
}

@media screen and (max-width: 620px) {
  .point-of-sale-app {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  .point-of-sale-app > .items-selector {
    grid-column: span 6/span 1 !important;
  }
  .point-of-sale-app > .items-selector > .items-container {
    grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
  }
  .point-of-sale-app > .item-details-container,
  .point-of-sale-app .customer-cart-container {
    grid-column: span 6/span 1;
  }
  .point-of-sale-app > .payment-container {
    overflow: scroll;
  }
  .point-of-sale-app > .payment-container > .fields-numpad-container {
    flex-direction: column-reverse;
  }
  .point-of-sale-app > .payment-container > .fields-numpad-container > .number-pad {
    display: none;
  }
  .point-of-sale-app > .payment-container > .fields-numpad-container > .fields-section {
    width: 100%;
  }
  .point-of-sale-app > .past-order-summary > .invoice-summary-wrapper {
    width: 100%;
  }
  .point-of-sale-app .numpad-totals > span {
    padding: 0 5px;
    font-size: var(--text-sm);
  }
  .point-of-sale-app .col > * {
    font-size: var(--text-sm) !important;
  }
  .point-of-sale-app .control-input-wrapper {
    padding-right: 0.15rem;
  }
  .point-of-sale-app .pay-amount {
    margin-right: 0.2rem;
  }
  .point-of-sale-app .past-order-list {
    grid-column: span 6/span 1;
  }
}